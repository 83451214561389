import React from 'react'
import classNames from 'classnames'
import { 
  Container, 
  Row, 
  Col, 
} from 'reactstrap'
import { Fluid, Offset } from 'components/Containers'
import ReactSVG from 'react-svg'
import Link from 'gatsby-link'
import logger from 'utils/logger';

import AutoForm from 'uniforms-unstyled/AutoForm';
import TextField   from 'uniforms-unstyled/TextField';
import NewsletterSubscribeSchema from 'api/schemas/NewsletterSubscribeSchema';

export default class ProductCategories extends React.Component {
  constructor(props) {
    super(props);
  }
  handleChange(key, value) {
    logger.debug('key', key, 'value', value);
  }
  render() {
    var propClasses = this.props.className;
    var propButtonClasses = this.props.btnClassName;
    var classes = classNames(
      'header-search', 
      'ptb-30em',
      'h-50vh',
      'text-center',
      {
        [`bg-${this.props.bgColor ? `${this.props.bgColor}` : `gray-cold-900` }`] : true
      },
      ... propClasses
    );
    return (
      <header className={classes}>
        <div className="content y-center">
          <Col lg={5} className={`ml-auto mr-auto`}>
            <h1>{this.props.title}</h1>
            <p className="lead">{this.props.excerpt}</p>
            {/*
            <AutoForm 
              schema={NewsletterSubscribeSchema} 
              showInlineError
              onSubmit={this.validSubmit}
              validate="onChangeAfterSubmit"
              onChange={(key, value) => {
                this.handleChange(key, value);
              }}
              model={{}}
              className={``}
            >
              <div className={`field-search-xl`}>
                <TextField
                  name="email"
                  placeholder="Search"
                  label={``}
                  errorMessage={`Please enter a valid email`}
                />
                <i className="icon icon-Magnifi-Glass2" />
              </div>
            </AutoForm>
            */}
          </Col>
        </div>
      </header>
    )
  }
}