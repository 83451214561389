import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import querystring from 'querystring';
import logger from 'utils/logger';
import SEO from 'components/SEO/SEO';

import { Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

import SupportCategories from 'components/Navigation/SupportCategories';
import SupportHeader from 'components/Headers/SupportHeader';

class Template extends React.Component {
  render () {
    logger.group(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.data('data', data);
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);

    const otherResources = [
      {
        icon: 'Download-fromCloud',
        title: 'Resources',
        description: 'Software, data sheets, guides, catalogs, etc.',
        slug: '/support/resources'
      },
      {
        icon: 'Support',
        title: 'Contact Us',
        description: 'Direct support contact information.',
        slug: '/company/contact'
      },
      // {
      //   icon: 'Flash-2',
      //   title: 'Recommended Hardware',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      //   slug: '/support/resources?category=recommended-hardware'
      // },
      {
        icon: 'Speach-Bubble3',
        title: 'FAQs',
        description: 'Frequently asked questions',
        newWindow: true,
        slug: 'http://www.apantac.net/osticket/kb/index.php'
      },
      {
        icon: 'Wrench',
        title: 'Online Support',
        description: 'Online support ticket system.',
        newWindow: true,
        slug: 'http://www.apantac.net/osticket/'
      },
      // {
      //   icon: 'Folder-Lock',
      //   title: 'Partner Resources',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      //   slug: '/support/resources?category=partners'
      // }
    ];

    logger.groupEnd();
    return (
      <Layout>
        <Fluid>
          <SEO
            title={`Support Center`}
            pathname={`/support`}
          />
          <SupportHeader
            title={`Support Center`}
            excerpt={`Welcome to Apantac support center. Learn about our products, view online documentation, and get the latest downloads.`}
            bgColor={`navy-500`}
          />
          {/*
          <section>
            <SupportCategories />
          </section>
          */}
          <section className={`otherResources ptb-20em`}>
            <Offset>
              <Row className={`pb-10em`}>
                <h1 className={`mrl-auto section-title`}>Other Resources</h1>
              </Row>
              <Row>
                { otherResources && otherResources.map((resource) => {
                  const target = resource.newWindow && { target: `_blank` };
                  return (
                    <Col key={`resource-${resource.slug}`}>
                      <div className={`resource-${resource.slug} feature-icon-lg`}>
                        <a href={`${resource.slug}`} {...target}>
                          <i className={`icon icon-${resource.icon}`} />
                          <h1>{resource.title}</h1>
                          <p>{resource.description}</p>
                        </a>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Template;

// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet title={`${`Support Center`}`} />
//         <Template data={data}>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );
