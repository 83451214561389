import React from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from 'reactstrap'
import { Fluid, Offset } from 'components/Containers'
import ReactSVG from 'react-svg'
import Link from 'gatsby-link'
import { withPrefix } from 'gatsby'

export default class SupportCategories extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { location, title } = this.props
    var propClasses = this.props.className;
    var propButtonClasses = this.props.btnClassName;
    var classes = classNames(
      'product-categories', 
      'bg-indigo-700', 
      'ptb-25em',
      'text-center',
      ... propClasses
    );
    const categories = [
      {
        slug: `all`,
        title: `All Products`,
        description: `Lorem ipsum products`,
        icon: `...`,
      },
      {
        slug: `multiviewers`,
        title: `Multiviewers`,
        description: `Lorem ipsum multiviewers`,
        icon: `...`,
      },
      {
        slug: `video-walls`,
        title: `Video Walls`,
        description: `Lorem ipsum video-walls`,
        icon: `...`,
      },
      {
        slug: `openGear`,
        title: `openGear`,
        description: `Lorem ipsum openGear`,
        icon: `...`,
      },
      {
        slug: `extenders`,
        title: `Extenders`,
        description: `Lorem ipsum extenders`,
        icon: `...`,
      },
      {
        slug: `matrices-and-switches`,
        title: `Matrices & Switches`,
        description: `Lorem ipsum matrices-and-switches`,
        icon: `...`,
      },
      {
        slug: `conerters-scalers`,
        title: `Converters & Scalers`,
        description: `Lorem ipsum conerters-scalers`,
        icon: `...`,
      },
      {
        slug: `splitters`,
        title: `DA & Splitters`,
        description: `Lorem ipsum conerters-scalers`,
        icon: `...`,
        className: `stroke`
      },
      {
        slug: `other`,
        title: `Other`,
        description: `Lorem ipsum other`,
        icon: `...`,
      },
    ]
    return (
      <nav className={classes}>
        
        <Fluid>
          <Offset>
            <Row>
              { categories && categories.map((category, i) => {
                return (
                  <Col key={`newsArticles-${i}`}>
                    <Link 
                      className="logo" 
                      to={`/products?category=${category.slug}`}
                      activeClassName={`active`}
                    >
                      <ReactSVG
                        path={withPrefix(`/img/icons/apantac-product-icons_${category.slug}.svg`)}
                        wrapperClassName="apantac-icon"
                      />
                      <p className={`mb-5em ff-sans fs-09em`}>{category.title}</p>
                      <p className={`mb-0 tt-uppercase fs-06em ls-100em alpha-50 lh-12`}>{category.description}</p>
                    </Link>
                  </Col>
                )
              })}
            </Row>
            <Row className={`mt-15em`}>
              <Col>
                <a href={`/media?category=cases`} className={`btn btn-md btn-outline-navy-400 color-white color-gray-cold-600-hover`}>Browse all the resources</a>
              </Col>
            </Row>
          </Offset>
        </Fluid>
      </nav>
    )
  }
}